// 招标类型管理
<template>
  <div class="user-manage">
    <table-search :total="total" :conditions="conditions" @searchRes="searchRes" @refresh="getList">
      <template v-slot:btns>
        <div class="btns">
          <el-button type="primary" size="medium" @click="addHandle">添加类型</el-button>
        </div>
      </template>
    </table-search>
    <div class="table">
      <el-table
        :data="tableData"
        border
        @filter-change="filterChange"
        style="width: 100%">
        <el-table-column
          prop="caseTypeId"
          label="类型ID">
        </el-table-column>
        <el-table-column
          prop="caseTypeName"
          label="类型名称">
        </el-table-column>
         <el-table-column
          prop="businessType"
          :filters="typeFilter"
          column-key="businessType"
          label="业务类型">
          <template slot-scope="scope">
            <span :style="{'color':COLOR.blue}" v-show="scope.row.businessType==1">诉讼业务</span>
            <span :style="{'color':COLOR.orange}" v-show="scope.row.businessType==2">非诉讼业务</span>
          </template>
        </el-table-column>
        <el-table-column
          prop=""
          label="360律师对应分类">
        </el-table-column>
        <el-table-column
          prop="createTime"
          label="入库时间">
        </el-table-column>
        <el-table-column
          prop="operatorId"
          label="最后操作用户ID">
        </el-table-column>
        <el-table-column
          prop="operatorName"
          label="最后操作用户名称">
        </el-table-column>
        <el-table-column
          prop="updateTime"
          label="最后操作时间">
        </el-table-column>
        <el-table-column
          prop="status"
          :filters="statusFilter"
          column-key="status"
          label="状态">
          <template slot-scope="scope">
            <span :style="{'color':COLOR.green}" v-show="scope.row.status==1">有效</span>
            <span :style="{'color':COLOR.red}" v-show="scope.row.status==0">无效</span>
          </template>
        </el-table-column>
        <el-table-column
          width="190"
          prop="val"
          label="操作">
          <template slot-scope="scope">
              <el-button type="text" @click="checkHandle(scope.row)">查看详情</el-button>
              <el-divider direction="vertical"></el-divider>
              <el-button type="text" @click="editHandle(scope.row)">编辑</el-button>
              <!-- <el-divider direction="vertical"></el-divider> -->
              <!-- <el-button type="text" @click="userHandle(scope.row)">无效</el-button> -->
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="footer flex-row">
      <div class="page">
        <el-pagination
          background
          :page-size="form.perPage"
          @size-change="handleSizeChange"
          layout="prev, pager, next,sizes,jumper"
          :page-sizes="[10, 20, 30, 40]"
          @current-change="currentChange"
          :total="total">
        </el-pagination>
      </div>
    </div>
    <add-bidtype ref="addBidtype" @ensure="addEnsure" :type="type"></add-bidtype>
    <bidtype-detail ref="bidtypeDetail" :info="info"></bidtype-detail>
  </div>
</template>

<script>
import TableSearch from '../../components/table-search.vue'
import {caseTypeList,caseTypeDetail,addCaseType,updateCaseType} from '../../api/system'
import AddBidtype from './coms/add-bidtype.vue'
import BidtypeDetail from './coms/bidtype-detail.vue'
export default {
  data () {
    return {
      info:{},
      type:'add',
      caseTypeId:'',
      total:0,
      form:{
        perPage:10,
        page:1,
        businessType:'',
        status:''
      },
      conditions:[
        {value:'caseTypeName',label:'类型名称'}
      ],
      tableData:[
        
      ],
      multipleSelection:[],
      applyFilter:[
        {text: '诉讼业务', value: '1'}, 
        {text: '婚姻家庭', value: '2'}, 
        {text: '合同纠纷', value: '3'},
        {text: '非诉讼业务', value: '4'},
        {text: '法律顾问', value: '5'},
        {text: '劳动纠纷', value: '6'},
        {text: '金融证券', value: '7'},
        {text: '公司经营', value: '8'},
        {text: '法律文书', value: '9'},
      ],
        typeFilter:[
            {text: '诉讼业务', value: 1},
            {text: '非诉讼业务', value: 2}, 
        ],
        subjectFilter:[
            {text: '超级管理员', value: '1'},
            {text: '管理员', value: '2'}, 
            {text: '技术组', value: '3'}, 
            {text: '运营组', value: '4'}, 
            {text: '财务组', value: '5'}, 
            {text: '产品组', value: '6'}, 
        ],
        statusFilter:[
            {text: '有效', value: 1},
            {text: '无效', value: 0}
        ]

    };
  },
  components: {
    TableSearch,
    AddBidtype,
    BidtypeDetail
  },
  computed: {},
  methods: {
    searchRes(para){
      this.form = Object.assign({
        page:1,
        perPage:this.form.perPage,
        businessType:this.form.businessType,
        status:this.form.status
        },para);
      this.getList();
    },
    getList(){
      caseTypeList(this.form).then(res=>{
        let data = res.data;
        if(data.code === '000'){
          this.tableData = data.content.list;
          this.total = data.content.total;
        }else{
          console.log(data.desc)
        }
      })
    },
    // 分页
    currentChange(val){
      this.form.page = val;
      this.getList();
    },
    handleSizeChange(val){
      this.form.perPage =val;
      this.form.page = 1;
      this.getList();
    },
    addHandle(){
      this.type = 'add';
      this.$refs.addBidtype.dialogVisible = true;
    },
    addEnsure(form){
      if(this.type === 'add'){
        addCaseType(form).then(res=>{
          let data = res.data;
          if(data.code==='000'){
            this.$message.success(data.desc);
            this.getList();
          }else{
            this.$message.warning(data.desc);
          }
        })
      }
      if(this.type === 'edit'){
        let para={
          caseTypeId:this.caseTypeId,
          caseTypeName:form.name,
          status:form.status
        }
        updateCaseType(para).then(res=>{
          let data = res.data;
          if(data.code==='000'){
            this.$message.success(data.desc);
            this.getList();
          }else{
            this.$message.warning(data.desc);
          }
        })
      }
      
    },
    checkHandle(row){
      let para={
        caseTypeId:row.caseTypeId,
        caseTypeName:row.caseTypeName
      }
      caseTypeDetail(para).then(res=>{
          let data = res.data;
          if(data.code==='000'){
            this.info = data.content;
            this.$refs.bidtypeDetail.dialogVisible = true;
          }else{
            this.$message.warning(data.desc);
          }
        })
    },
    editHandle(row){
      this.type="edit";
      this.caseTypeId = row.caseTypeId;
      this.$refs.addBidtype.form = {
        name:row.caseTypeName,
        businessType:row.businessType,
        status:row.status
      }
      this.$refs.addBidtype.dialogVisible = true;
    },
    forbidAll(){},
    forbid(){},
    filterChange(filters){
      for(let key in filters){
        this.form[key] = filters[key].join();
      }
      this.form.page = 1;
      this.getList();
    },
  },
  created(){
    this.getList();
  }
}

</script>
<style lang='scss' scoped>
.footer{
  justify-content: flex-end;
  margin:15px 0;
}
.dot{
  font-size: 20px;
  padding-right: 5px;
  vertical-align:middle;
}
</style>