<!-- 招标类型详情 -->
<template>
<el-dialog
  title="招标类型详情"
  :visible.sync="dialogVisible"
  width="50%"
  :before-close="handleClose">
  <div>
    <el-descriptions title="">
      <el-descriptions-item label="业务类型">{{info.businessType == 1?'诉讼类型':'非诉讼类型'}}</el-descriptions-item>
      <el-descriptions-item label="类型ID">{{info.caseTypeId}}</el-descriptions-item>
      <el-descriptions-item label="类型名称">{{info.caseTypeName}}</el-descriptions-item>
      <el-descriptions-item label="入库时间">
        {{info.createTime}}
      </el-descriptions-item>
      <el-descriptions-item label="操作人ID">{{info.operatorId}}</el-descriptions-item>
      <el-descriptions-item label="操作人名称">{{info.operatorName}}</el-descriptions-item>
      <el-descriptions-item label="状态">{{info.status ==0?'无效':'有效'}}</el-descriptions-item>
      <el-descriptions-item label="最后操作时间">{{info.updateTime}}</el-descriptions-item>
  </el-descriptions>
  </div>
  <span slot="footer" class="dialog-footer">
    <el-button type="primary" @click="dialogVisible = false">关 闭</el-button>
  </span>
</el-dialog>
</template>

<script>
export default {
  data () {
    return {
      dialogVisible:false
    };
  },
  props:['info'],
  components: {},
  computed: {},
  methods: {
    handleClose(done){
      this.dialogVisible = false;
      done();
    }
  }
}

</script>
<style lang='scss' scoped>
</style>