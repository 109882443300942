<!-- 新增招标类型 -->
<template>
<el-dialog :title="type === 'add' ? '添加招标类型':'编辑招标类型'" :visible.sync="dialogVisible">
  <el-form :model="form" label-width="100px">
    <el-form-item label="类型名称">
      <el-input v-model="form.name" autocomplete="off" style="width:400px;"></el-input>
    </el-form-item>
    <el-form-item label="业务类型" v-if="type === 'add'">
      <el-select v-model="form.businessType" placeholder="请选择">
        <el-option label="诉讼业务" :value="1"></el-option>
        <el-option label="非诉讼业务" :value="2"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="状态">
      <el-select v-model="form.status" placeholder="请选择">
        <el-option label="无效" :value="0"></el-option>
        <el-option label="有效" :value="1"></el-option>
      </el-select>
    </el-form-item>
  </el-form>
  <div slot="footer" class="dialog-footer">
    <el-button @click="cancel">取 消</el-button>
    <el-button type="primary" @click="ensure">确 定</el-button>
  </div>
</el-dialog>
</template>

<script>
export default {
  data () {
    return {
      dialogVisible:false,
      form:{
        // name:'',
        // businessType:'',
        // status:''
      }
    };
  },
  props:['type'],
  components: {},
  computed: {},
  methods: {
    ensure(){
      this.$emit('ensure',this.form);
      this.dialogVisible = false;
      this.form = {};
    },
    cancel(){
      this.form = {};
      this.dialogVisible = false;
    }
  }
}

</script>
<style lang='scss' scoped>
</style>